import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>© 2024 - {new Date().getFullYear()} Musix Free. All rights reserved.</p>
        <p className="footer-text">Welcome to the world of MusixFree, where rainbows of melodies and harmonious tunes await you! Here at MusixFree, we're all about providing an extensive library of royalty-free music for everyone to enjoy. From the soothing sounds of nature to the electrifying beats of modern pop, our channel has it all!</p>
        <p className='footer-text'>Visit our Youtube channel:
        <a href="https://www.youtube.com/channel/UC2CmXavuIp8-oQv3oK-h4AQ" target="_blank" rel="noopener noreferrer" className="youtube-link">
          <button className="youtube-button">HERE</button>
        </a>
         </p>
      </div>
    </footer>
  );
};

export default Footer;
