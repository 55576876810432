import React from 'react';

const Pagination = ({ currentPage, totalPages, handleChangePage }) => {
  const renderPaginationButtons = () => {
    const maxVisibleButtons = 5; // Nombre maximal de boutons visibles
    const buttons = [];

    // Afficher les boutons pour les premières pages
    for (let i = 1; i <= Math.min(maxVisibleButtons, totalPages); i++) {
      buttons.push(
        <button
          key={i}
          className={`pagination-button ${currentPage === i ? 'current-page' : ''}`}
          onClick={() => handleChangePage(i)}
        >
          {i}
        </button>
      );
    }

    // Ajouter les trois petits points si nécessaire
    if (totalPages > maxVisibleButtons) {
      if (currentPage > maxVisibleButtons - 2) {
        buttons.splice(1, 0, <span key="ellipsis1" className="pagination-ellipsis">...</span>);
      }
      if (currentPage < totalPages - 1) {
        buttons.splice(-1, 0, <span key="ellipsis2" className="pagination-ellipsis">...</span>);
      }
    }

    return buttons;
  };

  return (
    <div className="pagination">
      <button className="pagination-button" onClick={() => handleChangePage(1)}>First</button>
      <button className="pagination-button" onClick={() => handleChangePage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
      {/* Appel à la fonction pour afficher les boutons de pagination */}
      {renderPaginationButtons()}
      <button className="pagination-button" onClick={() => handleChangePage(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
      <button className="pagination-button" onClick={() => handleChangePage(totalPages)}>Last</button>
    </div>
  );
};

export default Pagination;
