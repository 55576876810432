import Footer from "./Components/Footer";
import Header from "./Components/Header";
import MusicsTable from "./Components/MusicsTable";
import ReactGA from "react-ga4";

function App() {

  const TRACKING_ID = 'G-R8KY5CZ1EF';  
  ReactGA.initialize(TRACKING_ID);
  // ReactGA.send({ hitType: "pageview", page: "/", title: "Page View" });
  ReactGA._gaCommandSendPageview(document.location.pathname);
   
  return (
    <div className="App">
      <Header />
      <MusicsTable />
      <Footer />
    </div>
  );
}

export default App;
